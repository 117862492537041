import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '@app/shared/services/shared.service';
import { TokenService } from '@app/shared/services/token.service';
import { User } from '@app/_models/user';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  returnUrl: string;
  email:string;
  password:string;
  errorMsg:string;
  incorrectCredentials: boolean = false

  constructor(public router: Router,private route: ActivatedRoute ,public sharedservice:SharedService, public tokenService:TokenService) {
    if (this.tokenService.getRefreshToken()) {
      this.router.navigate(['dashboard']);
    }
  }

  ngOnInit(): void {
    this.returnUrl = '/dashboard';
  }

  login() {

    this.incorrectCredentials = false

    if (this.email !== '' && this.password !== '') {
      this.sharedservice.login(this.email.trim(), this.password).subscribe(data => {
        
        localStorage.setItem("currentuser", JSON.stringify({ email: data.email, username: this.email, roles: data.roles }));

        this.sharedservice.getUserByUsername(data.username, data.userType).subscribe(res => {

          if (res.data && res.data.reset_password_required) {
            this.router.navigate(['reset/password', res.data.id]);
          } else {
            this.sharedservice.setTokens()
            this.router.navigate([this.returnUrl]);
          }

        }, error => {
          this.router.navigate([this.returnUrl]);
        })

      },
        error => {
          if (error == 'Please enter correct User Id and Password') {
            this.incorrectCredentials = true
          }
          console.log(error);
        }
      )
    } else {
      this.errorMsg = "Please enter valid details";
      return;
    }
  }
}
